//==============================================================================================
// CONTACT
//----------------------------------------------------------------------------------------------
// Defines styles associated with with the contact pane.
//----------------------------------------------------------------------------------------------
// NOTE: If this component requires its own helpers, they should be defined in a separate
// _placeholders.scss, _mixins.scss, or _functions.scss file and imported here.
//==============================================================================================

//----------------------------------------------------------------------------------------------
// IMPORTS
//----------------------------------------------------------------------------------------------

//----------------------------------------------------------------------------------------------
// VARIABLES
//----------------------------------------------------------------------------------------------

//----------------------------------------------------------------------------------------------
// GENERAL
//----------------------------------------------------------------------------------------------

address,
.contact-info {
  display                       : inline-block;
  padding-bottom                : 40px;
}

address {
  font-style                    : normal;
}

.contact-info {
  float                         : right;
  margin-right                  : 40px;
  i.fas {
    color                       : $grey;
  }
}

.map-container {
//margin-top                    : 40px;
//padding-top                   : 56.25%;
  clear                         : both;
  position                      : relative;
  overflow                      : hidden;
  width                         : 505px;
  height                        : 505px;
  box-shadow                    : 0 0 8px 0 rgba(0, 0, 0, 0.1);
}

.map-frame {
  position                      : absolute;
  top                           : 0;
  left                          : 0;
  border                        : 0;
  width                         : 100%;
  height                        : 100%;
}

//----------------------------------------------------------------------------------------------
// LARGE SCREEN
//----------------------------------------------------------------------------------------------
@media only screen and (max-width: 1023px) and (orientation: portrait) {
  address,
  .contact-info {
    padding-bottom              : 24px;
  }
  .contact-info {
    clear                       : both;
    float                       : none;
    margin-right                : 0;
  }
}


//----------------------------------------------------------------------------------------------
// MEDIUM SCREEN
//----------------------------------------------------------------------------------------------
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .map-container {
    width                       : 300px;
    height                      : 300px;
  }
}

//----------------------------------------------------------------------------------------------
// SMALL SCREEN
//----------------------------------------------------------------------------------------------
@media only screen and (max-width: 767px) {
  .map-container {
    width                       : 260px;
    height                      : 260px;
  }
}