﻿//==============================================================================================
// BASE CLASSES
//----------------------------------------------------------------------------------------------
// Provides global overrides for standard HTML elements based on element, attribute, and single-
// class selectors. Selectors in this section should never be nested more than two layers deep.
//----------------------------------------------------------------------------------------------

//----------------------------------------------------------------------------------------------
// IMPORTS
//----------------------------------------------------------------------------------------------

//----------------------------------------------------------------------------------------------
// FONTS
//----------------------------------------------------------------------------------------------
@import url('https://fonts.googleapis.com/css?family=Nunito&display=swap');
@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/brands.scss";

//----------------------------------------------------------------------------------------------
// TYPOGRAPHY
//----------------------------------------------------------------------------------------------

body {
  margin                        : 0px;
  padding                       : 0px;
  font-family                   : 'Nunito', sans-serif;
  font-weight                   : 400;
  background-color              : #faf9f5;
}
@media only screen and (max-width: 767px) {
  body {
    position                    : relative;
    top                         : 60px;
  }
}

h1,
h2,
p,
a {
  color                         : $black;
}
h1,
h2,
p {
  font-weight                   : 400;
}

//----------------------------------------------------------------------------------------------
// IMAGES
//----------------------------------------------------------------------------------------------
.logo {
  object-fit                    : scale-down;
  margin                        : 3em 0em;
  width                         : 45%;
}
