//==============================================================================================
// MENU
//----------------------------------------------------------------------------------------------
// Defines styles associated with with the primary navigation menu(s).
//----------------------------------------------------------------------------------------------
// NOTE: If this component requires its own helpers, they should be defined in a separate
// _placeholders.scss, _mixins.scss, or _functions.scss file and imported here.
//==============================================================================================

//----------------------------------------------------------------------------------------------
// IMPORTS
//----------------------------------------------------------------------------------------------

//----------------------------------------------------------------------------------------------
// VARIABLES
//----------------------------------------------------------------------------------------------

//----------------------------------------------------------------------------------------------
// GENERAL
//----------------------------------------------------------------------------------------------

nav {
  z-index                       : 1000;
  position                      : fixed;
  top                           : 0px;
  left                          : 15%;
  box-shadow                    : 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  width                         : 225px;
  height                        : 100%;
  text-align                    : center;
  background-color              : rgba(250, 249, 245, 0.7);

  a {
    display                     : block;
    position                    : relative;
    font-size                   : 1.05em;
    font-weight                 : 600;
    line-height                 : 3.5em;
    text-decoration             : none;

    i.fa-angle-right {
      display                   : none;
      position                  : absolute;
      top                       : 21px;
      right                     : 20px;
    }

    &.menu-hamburger {
      display                   : none;
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
      color                     : $red;

      i.fa-angle-right {
        display                 : inline-block;
      }

    }

  }

  ul {
    list-style-type             : none;
    margin                      : 0;
    padding-left                : 0;
  }

}

//----------------------------------------------------------------------------------------------
// MEDIUM SCREEN
//----------------------------------------------------------------------------------------------
@media only screen and (max-width: 1023px) {
  nav {
    left                        : 0;
  }
}

//----------------------------------------------------------------------------------------------
// SMALL SCREEN
//----------------------------------------------------------------------------------------------

@media only screen and (max-width: 767px) {
  nav {
    width                       : 100%;
    height                      : 60px;
    background-color            : rgba(250, 249, 245, 1);
    transition                  : background-color 0.7s ease;

    img.logo {
      position                  : absolute;
      top                       : 15px;
      left                      : 15px;
      margin                    : 0;
      width                     : auto;
      height                    : 30px;
    }

    a {

      &:hover,
      &:focus,
      &:active,
      &.active {
        i.fa-angle-right {
          display               : none;
        }
      }

      &.menu-hamburger {
        display                 : inline-block;
        position                : absolute;
        top                     : 17px;
        right                   : 15px;
        font-size               : 30px;
        line-height             : 1;
      }

    }

    ul {
      display                   : block;
      position                  : fixed;
      background-color          : rgb(255,255, 255);
      top                       : 60px;
      left                      : -160px;
      height                    : 100%;
      text-align                : left;
      box-shadow                : 8px 0 10px -11px rgba(0,0,0,0.1);
      li {
        padding-right           : 40px;
        padding-left            : 15px;
      }
    }

    &.open {
      background-color          : rgb(255, 255, 255);
    }

    &.closed {
      a.menu-hamburger {
        color                   : $black;
      }

    }

  }
}