﻿//==============================================================================================
// VARIABLES
//----------------------------------------------------------------------------------------------
// Primary customization point for making expected modifications to the site.
//----------------------------------------------------------------------------------------------
// NOTE: While these variables are meant to be specific to the GoldSim website styles, they may
// overlap with or supercede variables set in the GoldSim-specific Foundation settings
// (_foundationSettings.scss, which imports/inherits this file).
//==============================================================================================

//----------------------------------------------------------------------------------------------
// COLORS
//----------------------------------------------------------------------------------------------

$black                          : rgb(1, 1, 1);
$red                            : rgb(255, 51, 0);
$grey                           : rgb(175, 174, 171);

//----------------------------------------------------------------------------------------------
// BASE/GENERAL
//----------------------------------------------------------------------------------------------

//----------------------------------------------------------------------------------------------
// LAYOUT
//----------------------------------------------------------------------------------------------

//----------------------------------------------------------------------------------------------
// COMPONENTS
//----------------------------------------------------------------------------------------------

//----------------------------------------------------------------------------------------------
// VENDOR
//----------------------------------------------------------------------------------------------
$fa-font-path                   : "/Shared/Fonts/Vendor/FontAwesome/";
$afc-font-family                : 'Font Awesome\ 5 Free';