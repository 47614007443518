﻿//==============================================================================================
// LAYOUT
//----------------------------------------------------------------------------------------------
// Provides structural definitions for the site's layout, such grids, dimensions, positioning,
// z-indexes, margins, and padding.
//----------------------------------------------------------------------------------------------
// IMPORTANT: Skinning attributes such as font size, color, backgrounds, and gradients should
// not be defined in layout; these should be handled elsewhere (e.g., /Base) to maintain
// separation of concerns.
//----------------------------------------------------------------------------------------------
// NOTE: Since layout sections often map to exclusive parts of a page, it is acceptable to
// use #ID selectors here (and ONLY here).
//----------------------------------------------------------------------------------------------
// NOTE: Layouts in this file should be migrated to individual files under /Layout and imported
// if they get too long (say, over 10 selectors).
//==============================================================================================

//----------------------------------------------------------------------------------------------
// IMPORTS
//----------------------------------------------------------------------------------------------

//----------------------------------------------------------------------------------------------
// FOOTER
//----------------------------------------------------------------------------------------------

#Footer {
  padding-top                   : 240px;
  p {
    color                       : rgba(1, 1, 1, 0.5);
  }
}

@media only screen and (max-width: 767px) {
  #Footer {
    padding-top                 : 80px;
  }
}

//----------------------------------------------------------------------------------------------
// CONTENT PANES
//----------------------------------------------------------------------------------------------

.pane {
  margin-left                   : 34.5%;
  padding                       : 50px;
  width                         : 50%;
  min-width                     : 300px;
  max-width                     : 550px;
//min-height                    : 500px;
//height                        : 300px;
  font-size                     : 1.125rem;
  line-height                   : 1.5;
  h1 {
    font-size                   : 2.25rem;
  }
  h2 {
    font-size                   : 1.125rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
  .pane {
    margin-left                 : 29.5%;
  }
}

@media only screen and (max-width: 767px) {
  .pane {
    margin-left                 : 0;

    h1 {
      margin-top                : 0;
    }
  }
}

//----------------------------------------------------------------------------------------------
// INTERMISSION PANES
//----------------------------------------------------------------------------------------------

.intermission {
  z-index                       : 0;
  position                      : relative;
  height                        : 250px;
  background-repeat             : no-repeat;
  background-attachment         : fixed;
  background-size               : cover;
  h1 {
    transform                   : translate(-50%, -50%);
    display                     : block;
    position                    : absolute;
    top                         : 50%;
    left                        : 50%;
    margin-left                 : 5%;
    padding-left                : 38px;
    color                       : #fff;
    width                       : 40%;
    font-size                   : 3rem;
    line-height                 : 1.5;
    text-align                  : center;
    text-shadow                 : 0px 0px 10px rgba(0, 0, 0, 0.4);
  }

}

@media only screen and (min-width: 768px) and (max-width: 1023px) and (orientation: portrait) {
  .intermission {
    h1 {
      margin-left               : 11%;
      width                     : 60%;
      font-size                 : 2.5rem;
    }
  }
}

@media only screen and (max-width: 767px) {
  .intermission {
    height                      : 180px !important;
  }
}

.mobile .intermission {
  background-attachment         : scroll;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .intermission {
    background-attachment       : scroll;
  }
}